/* -------------------------------------------
 * ELEMENTS
---------------------------------------------- */
blockquote, q { quotes: none; margin: 0; }
blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }
ol, ul, li { margin: 0; padding: 0; list-style: none }
img, a img { border: none; vertical-align: bottom; }
p, a, p a, dt, dd { color: $black; text-decoration: none; }
table { border-collapse: collapse; border-spacing: 0; }
address { font-style: normal; }
img { max-width: 100%; height: auto; width: 100%; }
html { font-size: 100%; font-style: inherit; font-family: inherit; font-weight: inherit; overflow-y: scroll; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
body {
  font: 14px/1.8 "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Arial, sans-serif;
  font-feature-settings: 'palt' 1;
  *font-size:small;
  *font:x-small;
  margin: 0;
  padding: 0;
}
p {
  letter-spacing: 0.05rem;
}
a {
  color: $black;
  display: block;
  transition: opacity 200ms 0s ease;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.65;
  }
}
input[type="submit"], input[type="text"], input[type="tel"], input[type="email"], 
button, textarea, select {
  -webkit-appearance: none;
  border-radius: 0;
}
button, input[type="submit"] {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
::placeholder{
  color: $gray
}