@charset "UTF-8";

@import "../../../node_modules/normalize.css/normalize";

@import "foundation/_1_setting.scss";
@import "foundation/_2_mixins.scss";
@import "foundation/_3_elements.scss";
// @import "layout/**";
// @import "modules/**";

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.gallery-item {
  position: relative;
  overflow: hidden;
  width: 33.3333%;
  margin: 0;
  padding-top: 33.3333%;
  box-sizing: border-box;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #f7f7f7;
    top: 0;
    z-index: -1;
  }
  a {
    &:hover {
      opacity: 1;
      img {
        transform: scale(1.2) translate(0%, -42%);
      }
      h3 {
        opacity: 1;
      }
    }
    img {
      position: absolute;
    top: 50%;
    left: 0%;
    transform-origin: center;
    transform: scale(1) translate(0%,-50%);
    transition: all .2s 0s ease;
    }
    h3 {
      opacity: 0;
      display: block;
      color: #fff;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      word-wrap: break-word;
      @include fz(14px);
      line-height: 2;
      letter-spacing: 0.1rem;
      padding: 5%;
      margin: 0;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.65);
      transition: all 0.2s 0s ease;
    }
  }
}

@media screen and (max-width: 768px) {
  .gallery-item {
    width: 50%;
  }
}