/* -------------------------------------------
 * MIXINS
---------------------------------------------- */

/* 
 * CLEARFIX
 */
@mixin clr() {
  & {
    *zoom: 1;
  }
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* 
 * FONT-SIZE
 */
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
@mixin fz($size) {
  font-size: $size;
  font-size: calculateRem($size);
}
